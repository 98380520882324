import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import { AWSResponsePresignURLType } from 'types';

const controller = `files`;
export type fileMeta = {
  fileType: string;
  fileExtension: string;
  fileName?: string;
  folder?: string;
};
export type ReportsPayload = {
  startDate?: Date;
  endDate: Date;
};

export const getPresigned = (
  payload: fileMeta
): Promise<AxiosResponse<AWSResponsePresignURLType>> =>
  httpApi
    .post<AWSResponsePresignURLType>(`${controller}/getpresignedurl`, {
      ...payload,
    })
    .then((res) => res);
