import { StatusMessages } from 'types/statusMessage';
import base from './base';
import cycle from './cycle';
import auth from './auth';
import career from './career';
import check from './check';
import contract from './contract';
import devices from './devices';
import event from './event';
import group from './group';
import subjects from './subjects';
import holiday from './holiday';
import incidence from './incidence';
import reports from './reports';
import schedule from './schedule';
import user from './user';
import groupSchedule from './group-schedule';

const statusMessages: StatusMessages = {
  base,
  auth: { ...base, ...auth },
  career: { ...base, ...career },
  check: { ...base, ...check },
  contract: { ...base, ...contract },
  cycle: { ...base, ...cycle },
  devices: { ...base, ...devices },
  event: { ...base, ...event },
  group: { ...base, ...group },
  subjects: { ...base, ...subjects },
  holiday: { ...base, ...holiday },
  incidence: { ...base, ...incidence },
  reports: { ...base, ...reports },
  schedule: { ...base, ...schedule },
  user: { ...base, ...user },
  groupSchedule: { ...base, ...groupSchedule },
};

export default statusMessages;
