import { Avatar, Button, Col, MenuProps, Row } from 'antd';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { Link } from 'react-router-dom';
import { User } from 'types';
import { authPaths } from 'constants/routePaths';
import { randomAvatarColor } from 'hooks/getRandomAvatarColor';
import { DownOutlined } from '@ant-design/icons';
import 'styles/sass/profile.scss';

interface ProfileDropdownProps {
  user: User;
  impersonateMode?: boolean;
  showName?: boolean;
}

export const ProfileDropdown = ({
  user,
  impersonateMode,
  showName,
}: ProfileDropdownProps) => {
  const items: MenuProps['items'] = [
    {
      key: 'logout',
      label: (
        <Link
          className="error-txt"
          to={`${authPaths.basePath}/${authPaths.logout}`}
        >
          Salir
        </Link>
      ),
    },
  ];

  return (
    <div id="profile-dropdown">
      <Row align="middle">
        <Col xs={24}>
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            disabled={impersonateMode}
            className={`${impersonateMode ? 'impersonate-mode' : ''}`}
            rootClassName="dropdown-profile"
          >
            <Button type="text">
              <Row align="middle" gutter={16}>
                <Col>
                  <Avatar style={randomAvatarColor()}>
                    {user.name.charAt(0)}
                    {user.lastName.charAt(0)}
                  </Avatar>
                </Col>
                {showName && (
                  <Col>
                    <p className="profile-name">
                      <b>{`${user.name} ${user.lastName}`}</b>
                    </p>
                  </Col>
                )}
                {!impersonateMode && (
                  <Col>
                    <DownOutlined className="down-icon" />
                  </Col>
                )}
              </Row>
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
};
