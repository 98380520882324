import {
  Col,
  Row,
  notification,
  Typography,
  Skeleton,
  Table,
  Button,
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import type { UploadProps, UploadFile } from 'antd/es/upload/interface';
import { ResponseExcelType, badFormat, registryByExcel } from 'api/user.api';
import { fileMeta, getPresigned } from 'api/file.api';
import { NotificationPlacement } from 'antd/es/notification/interface';
import { InfoUplad } from './UploadUsers/InfoUplad';
import { usersPaths } from 'constants/routePaths';
import * as Sentry from '@sentry/react';
import notificationHook from 'hooks/notificationHook';
import { ApiError } from 'types';
import statusMessages from 'statusMessages';
import { BackButton } from 'components/common/BackButton/BackButton';

export const UploadUsers = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [presignedURL, setPresignedURL] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileMeta, setFileMeta] = useState<fileMeta>();
  const [fileName, setFileName] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const columns = [
    {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user',
      render: (_: string, item: badFormat) => <>{item.reason.user}</>,
    },
    {
      title: 'Razon',
      dataIndex: 'msg',
      key: 'msg',
      render: (_: string, item: badFormat) => <>{item.reason.msg}</>,
    },
  ];

  const openNotification = (
    placement: NotificationPlacement,
    context: ResponseExcelType | badFormat[]
  ) => {
    api[Array.isArray(context) ? 'error' : 'info']({
      message: Array.isArray(context)
        ? 'Hubo errores al subir el archivo:'
        : 'Archivo Subido:',
      description: (
        <>
          {Array.isArray(context) ? (
            <>
              <Table
                dataSource={context}
                columns={columns}
                pagination={{ position: ['bottomCenter'], defaultPageSize: 5 }}
                className="table-ps"
                bordered
                size="small"
              ></Table>
            </>
          ) : (
            <>
              <Typography.Paragraph>
                {context.succesMsg ?? ''}
              </Typography.Paragraph>
              <Typography.Paragraph>
                {context.failedMsg ?? ''}
              </Typography.Paragraph>
            </>
          )}
        </>
      ),
      placement,
    });
  };
  const handleUpload = async () => {
    setUploading(true);
    setLoading(true);
    await fetch(presignedURL, {
      method: 'PUT',
      body: selectedFile,
    }).catch((error) => {
      Sentry.captureException(error);
      const currentStatus = (error as ApiError).statusCode;
      notificationHook({
        type: 'error',
        message: statusMessages.files[
          currentStatus ? currentStatus : 500
        ] as string,
      });
    });

    registryByExcel(`${fileName}.${fileMeta?.fileExtension}`)
      .then((a) => {
        setFileList([]);
        openNotification('top', a.data);
        // message.success('upload successfully.');
      })
      .catch((error) => {
        Sentry.captureException(error);
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.user[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      })
      .finally(() => {
        setUploading(false);
        setLoading(false);
      });
  };
  const props: UploadProps = {
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const isExcel = file.type === 'application/vnd.ms-excel';
      const isCSV = file.type === 'text/csv';
      const isXLSX =
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const isValidFileType = isExcel || isCSV || isXLSX; // Permitir archivos de Excel, CSV o XLSX
      if (!isValidFileType) {
        notificationHook({
          type: 'error',
          message: 'Debe ser un archivo de Excel.',
        });
        return false;
      }
      setFileList([file]);
      const meta = {
        fileType: file.type,
        fileExtension: file.name.split('.')[1],
      };
      const presigned = await getPresigned(meta);
      if (presigned) {
        setPresignedURL(presigned.data.url);
        setSelectedFile(file);
        setFileName(presigned.data.fileId);
        setFileMeta(meta);
      }
      return false;
    },
    fileList,
  };

  return (
    <div id="container">
      {contextHolder}
      <h2 className="title">Subir empleados por excel</h2>
      <BackButton />
      <div id="upload-employees-container">
        {loading ? (
          <Skeleton active style={{ marginTop: 30 }} />
        ) : (
          <div id="upload-employees">
            <Row justify="center">
              <Col className="example-upload-employee-container" xs={16}>
                <InfoUplad />
              </Col>
            </Row>
            <div className="upload-dragger">
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Haz clic o arrastra un archivo en esta área para subirlo
                </p>
                <p className="ant-upload-hint">
                  Recuerda subir en archivos unicamente <b>.csv</b> <b>.xls</b>{' '}
                  <b>.xlsx</b>
                </p>
              </Dragger>
            </div>
            <Row gutter={10} justify="end">
              <Col>
                <Button
                  type="default"
                  onClick={() => navigate(usersPaths.basePath)}
                  id="cancelBtn"
                >
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button
                  id="uploadEmployeesBtn"
                  type="primary"
                  onClick={handleUpload}
                  disabled={fileList.length === 0}
                  loading={uploading}
                >
                  {uploading ? 'Subiendo' : 'Subir'}
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};
